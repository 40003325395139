import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import PaginatedTable from '../PaginatedTable';
import MonitorTransactionLink from './MonitorTransactionLink';
import { renderTimestamp } from '../../utils/DateUtils';
import NoExistingMonitorTransaction from './NoExistingMonitorTransaction';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const MonitorsTransactionTableRender = ({ data, loading }) => {
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Group Key</TableCell>
            <TableCell align="left" style={{ color: 'black', fontWeight: 'bold' }}>
              Created At
            </TableCell>
            <TableCell align="left" style={{ color: 'black', fontWeight: 'bold' }}>
              Status
            </TableCell>
            <TableCell align="left" style={{ color: 'black', fontWeight: 'bold' }}>
              Alerted
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell component="th" scope="row">
                <MonitorTransactionLink monitor_transaction={item} style={{ color: 'grey' }} />
              </TableCell>
              <TableCell align="left">{renderTimestamp(item?.created_at)}</TableCell>
              <TableCell align="left">{item?.status}</TableCell>
              <TableCell align="left">
                {item?.has_alerts ? <ErrorOutlineIcon style={{ color: 'red' }} /> : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!data?.length ? <NoExistingMonitorTransaction /> : null}
    </>
  );
};

const MonitorTransactionsTable = ({
  monitor_transactions,
  total,
  pageSize,
  pageUpdateCb,
  tableContainerStyles
}) => {
  return (
    <PaginatedTable
      renderTable={MonitorsTransactionTableRender}
      data={monitor_transactions}
      total={total}
      pageSize={pageSize}
      tableContainerStyles={tableContainerStyles ? tableContainerStyles : {}}
      pageUpdateCb={pageUpdateCb}
    />
  );
};

export default MonitorTransactionsTable;

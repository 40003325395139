import EventTypesTable from '../components/EventType/EventTypesTable';
import { useCallback, useEffect, useState } from 'react';
import API from '../API';
import Heading from '../components/Heading';
import TableSkeleton from '../components/Skeleton/TableLoader';
import SuspenseLoader from '../components/Skeleton/SuspenseLoader';
import MonitorsTable from '../components/Monitors/MonitorsTable';
import CreateMonitorLink from '../components/Monitors/CreateMonitorLink';

const Monitors = () => {
  const [loading, setLoading] = useState(true);

  const [monitors, setMonitors] = useState([]);

  const [total, setTotal] = useState(0);
  const [pageMeta, setPageMeta] = useState({ limit: 10, offset: 0 });

  const fetchMonitors = API.useGetMonitors();

  useEffect(() => {
    if (loading) {
      fetchMonitors(
        [],
        pageMeta,
        response => {
          setMonitors(response.data?.monitors);
          setTotal(Number(response.data?.meta?.total_count));
          setLoading(false);
        },
        err => {
          setLoading(false);
        }
      );
    }
  }, [loading]);

  const loadingCb = useCallback(() => {
    setLoading(true);
  }, [loading]);

  const pageUpdateCb = useCallback(
    (page, successCb, errCb) => {
      setPageMeta(page);
      fetchMonitors(
        [],
        page,
        resp => {
          setMonitors(resp.data?.monitors);
          setTotal(Number(resp.data?.meta?.total_count));
          successCb(resp.data?.monitors, Number(resp.data?.meta?.total_count));
        },
        err => {
          errCb(err);
        }
      );
    },
    [fetchMonitors]
  );

  return (
    <>
      <Heading heading={'Monitors'} onTimeRangeChangeCb={loadingCb} onRefreshCb={loadingCb}>
        <CreateMonitorLink />
      </Heading>
      <SuspenseLoader loading={!!loading} loader={<TableSkeleton />}>
        <MonitorsTable
          monitors={monitors}
          total={total}
          pageSize={pageMeta ? pageMeta?.limit : 10}
          pageUpdateCb={pageUpdateCb}
          tableContainerStyles={monitors?.length ? {} : { maxHeight: '35vh', minHeight: '35vh' }}
        ></MonitorsTable>
      </SuspenseLoader>
    </>
  );
};

export default Monitors;

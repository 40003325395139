import { React } from 'react';
import Card from '@mui/material/Card';
import { CardContent, Divider, Grid } from '@mui/material';
import MonitorLink from '../Monitors/MonitorLink';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { renderTimestamp } from '../../utils/DateUtils';
import CardHeader from '@mui/material/CardHeader';

const MonitorTransactionCard = ({ monitorTransaction }) => {
  return (
    <Card>
      <CardHeader title={'Transaction Details'} />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={6}
            >
              <Grid item>
                <Typography variant="body2" className="text-gray-500">
                  Monitor
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  <MonitorLink monitor={monitorTransaction?.monitor} />
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              xs={6}
            >
              <Grid item>
                <Typography variant="body2" className="text-gray-500">
                  Status
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">{monitorTransaction?.status}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              xs={6}
            >
              <Grid item>
                <Typography variant="body2" className="text-gray-500">
                  Created at
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {renderTimestamp(monitorTransaction?.created_at)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              xs={6}
            >
              <Grid item>
                <Typography variant="body2" className="text-gray-500">
                  Alerted
                </Typography>
              </Grid>
              <Grid item>
                {monitorTransaction?.has_alerts ? (
                  <ErrorOutlineIcon style={{ color: 'red' }} />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MonitorTransactionCard;

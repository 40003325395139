import React from 'react';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Layout from './Layout';
import BaseLayout from './BaseLayout';
import { Route, Routes } from 'react-router-dom'; // import React Router
import MetricsList from './components/Metrics/metrics-list';
import Home from './pages/Home';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import CreateMonitor from './components/Monitors/CreateMonitor';
import EventTypes from './pages/EventTypes';
import Alerts from './pages/Alerts';
import EventType from './pages/EventType';
import Integrations from './pages/Integrations';
import Workflows from './pages/Workflows';
import ConfirmEmail from './pages/ConfirmEmail';
import ApiTokens from './components/Apikeys/Apikeys';
import AxiosPrivate from './components/AxiosPrivate';
import Events from './pages/Events';
import Monitor from './pages/Monitor';
import Monitors from './pages/Monitors';
import MonitorTransaction from './pages/MonitorTransaction';
import Alert from './pages/Alert';

const App = () => {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/confirm-email/:key?" element={<ConfirmEmail />} />
      </Route>

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth />}>
          <Route element={<AxiosPrivate />}>
            <Route element={<Layout />}>
              <Route exact path="/" element={<Home />} />

              <Route path="/event-types" element={<EventTypes />} />
              <Route path="/event-types/:id/:tab?" element={<EventType />} />

              <Route path="/events" element={<Events />} />

              <Route path="/monitors" element={<Monitors />} />
              <Route path="/monitors/create" element={<CreateMonitor />} />
              <Route path="/monitors/:id/:tab?" element={<Monitor />} />

              <Route path="/monitor-transactions/:id/:tab?" element={<MonitorTransaction />} />

              <Route path="/metrics-alert" element={<MetricsList />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/alerts/:alertId?" element={<Alert />} />

              <Route path="/integrations" element={<Integrations />} />
              <Route path="/workflows" element={<Workflows />} />

              <Route path="/api-keys" element={<ApiTokens />} />
              <Route path="/settings" element={<MetricsList />} />
              <Route path="/logout" element={<MetricsList />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

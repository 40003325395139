import { Grid } from '@mui/material';
import React from 'react';
import TimeRangePicker from './TimeRangePicker';
import Refresh from '../Refresh';

const renderChildren = children => {
  return React.Children.map(children, child => {
    return <Grid item>{child}</Grid>;
  });
};

const Heading = ({ subHeading, heading, onTimeRangeChangeCb, onRefreshCb, children }) => {
  return (
    <>
      <div className="w-full py-3 flex justify-between bg-white border-b border-gray-300 px-4 items-center shadow-mds sticky">
        <div className="flex-col justify-items-center">
          {!!subHeading ? <div className="text-xs text-gray-400">{subHeading}</div> : null}
          <div className="text-xs sm:text-lg px-1 font-semibold text-gray-800">{heading}</div>
        </div>
        <div className="flex">
          {renderChildren(children)}
          {onTimeRangeChangeCb ? (
            <Grid item>
              <TimeRangePicker onTimeRangeChangeCb={onTimeRangeChangeCb} />
            </Grid>
          ) : null}
          {onRefreshCb ? (
            <Grid item>
              <Refresh onRefreshCb={onRefreshCb} />
            </Grid>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Heading;

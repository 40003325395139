import React, { useEffect, useState } from 'react';
import API from '../../API';
import moment from 'moment';
import { MaskCharacter } from '../../utils/Apikeys';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import TableSkeleton from '../Skeleton/TableLoader';
import SuspenseLoader from '../Skeleton/SuspenseLoader';
import CustomContainer from '../CustomContainer';
import Heading from '../Heading';

import CodeSnippet from '../CodeSnippet';

import { Grid } from '@mui/material';

const columns = [
  {
    field: 'key',
    headerName: 'Token',
    flex: 1,
    valueGetter: params => {
      const masked = MaskCharacter(String(params?.value), '*', 4);
      return masked;
    }
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    valueGetter: params => {
      const format = 'YYYY-MM-DD HH:mm:ss';
      const date = new Date(params?.value);
      return moment(date).format(format);
    }
  },
  {
    field: 'created_by',
    headerName: 'Created by',
    flex: 1
  },
  {
    flex: 1,
    renderCell: params => {
      const apiKeyVal = params.row.key;
      const handleCopyClick = val => () => {
        navigator.clipboard.writeText(val);
      };
      return (
        <Button variant="text" onClick={handleCopyClick(apiKeyVal)}>
          Copy Token
        </Button>
      );
    }
  }
];

const ApiTokens = () => {
  const [apiTokens, setApiTokens] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const fetchAccountApiToken = API.useGetAccountApiToken();

  useEffect(() => {
    setLoading(true);
    fetchAccountApiToken(
      {
        meta: {
          page: {
            limit: pageSize,
            offset: pageSize * page
          }
        }
      },
      res => {
        setLoading(false);
        setApiTokens(res.data?.account_api_tokens);
        setTotal(Number(res.data?.meta?.total_count));
        setPageSize(Number(res.data?.meta?.page?.limit));
      },
      err => {
        console.error(err);
        setIsError(true);
      }
    );
  }, [page, pageSize]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  const handlePageSizeChange = newPageSize => {
    setPageSize(newPageSize);
  };

  return (
    <>
      <Heading heading={'API keys'}></Heading>
      <SuspenseLoader loading={!!loading} loader={<TableSkeleton noOfLines={7} />}>
        <Paper sx={{ width: '100%', height: '360px' }}>
          <DataGrid
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }}
            disableColumnMenu
            sortable={false}
            pagination
            // loading={loading}
            paginationMode="server"
            rowCount={total}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={handlePageChange}
            rows={apiTokens}
            columns={columns.map(column => ({
              ...column,
              sortable: false
            }))}
            getRowId={params => params?.key}
            disableSelectionOnClick
          />
        </Paper>

        <Grid item xs={6} sx={{ mt: 1.0 }}>
          <CodeSnippet />
        </Grid>
      </SuspenseLoader>

      <Snackbar
        open={isError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          Something went wrong. Please contact Administrator
        </Alert>
      </Snackbar>
    </>
  );
};
export default ApiTokens;

export const EVENT_TYPES = {
  DELAYED_EVENT: 'DELAYED_EVENT',
  MISSING_EVENT: 'MISSING_EVENT'
};

export const EVENT_TYPE_DISPLAY = {
  [EVENT_TYPES.DELAYED_EVENT]: 'Delayed',
  [EVENT_TYPES.MISSING_EVENT]: 'Missing'
};

export const TRANSACTIONS_TYPE = {
  DELAYED_TRANSACTION: 'DELAYED_TRANSACTION',
  MISSING_TRANSACTION: 'MISSING_TRANSACTION'
};

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from './data/black_logo_beta.png';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import EventIcon from '@mui/icons-material/Event';
import SendIcon from '@mui/icons-material/Send';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import KeyIcon from '@mui/icons-material/Key';
import useLogout from './hooks/useLogout';
import '../src/Layout.css';

function Sidebar() {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="sidebar1 ">
      <Link to="/">
        <div className="py-2 px-2 border-b border-gray-300 bg-white">
          <img src={logo} alt="Logo" style={{ width: '129px' }} />
        </div>
      </Link>

      <div
        className=""
        style={{
          position: 'relative',
          height: '100vh'
        }}
      >
        <List
          sx={{
            width: '100%',
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: '#9553FE',
              '&, & .MuiListItemIcon-root': {
                color: '#FFFFFF'
              }
            }
          }}
        >
          <Link to="/">
            <ListItemButton
              name="Home"
              selected={selectedIndex === 0}
              onClick={event => handleListItemClick(event, 0)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <HomeIcon />
              </ListItemIcon>
              <span className="text-sm">Home</span>
              {/* <ListItemText primary="Home" /> */}
            </ListItemButton>
          </Link>
          <Link to="/monitors">
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={event => handleListItemClick(event, 2)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <LegendToggleIcon />
              </ListItemIcon>
              <span className="text-sm">Monitor</span>
              {/* <ListItemText primary="Monitors" /> */}
            </ListItemButton>
          </Link>

          <Link to="/alerts">
            <ListItemButton
              selected={selectedIndex === 4}
              onClick={event => handleListItemClick(event, 4)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <AddAlertIcon />
              </ListItemIcon>
              <span className="text-sm">Alerts</span>
              {/* <ListItemText primary="Alerts" /> */}
            </ListItemButton>
          </Link>
          <Link to="/events">
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={event => handleListItemClick(event, 3)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <EventIcon />
              </ListItemIcon>
              <span className="text-sm">Events</span>
              {/* <ListItemText primary="Events" /> */}
            </ListItemButton>
          </Link>
          <Link to="/event-types">
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={event => handleListItemClick(event, 1)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <SendIcon />
              </ListItemIcon>
              <span className="text-sm">Event Types</span>
              {/* <ListItemText primary="Event Types" /> */}
            </ListItemButton>
          </Link>
          <Link to="/api-keys">
            <ListItemButton
              selected={selectedIndex === 5}
              onClick={event => handleListItemClick(event, 5)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <KeyIcon />
              </ListItemIcon>
              <span className="text-sm">API Keys</span>
              {/* <ListItemText primary="API Keys" /> */}
            </ListItemButton>
          </Link>
          <Link to="/integrations">
            <ListItemButton
              selected={selectedIndex === 6}
              onClick={event => handleListItemClick(event, 6)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <KeyIcon />
              </ListItemIcon>
              <span className="text-sm">Integrations</span>
              <div className="py-1 text-xs rounded-full ml-2 px-2 font-medium border border-gray-200 bg-gray-100 text-gray-700">
                Coming Soon
              </div>
            </ListItemButton>
          </Link>
          <Link to="/workflows">
            <ListItemButton
              selected={selectedIndex === 7}
              onClick={event => handleListItemClick(event, 7)}
            >
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <KeyIcon />
              </ListItemIcon>
              <span className="text-sm">Workflows</span>
              <div className="py-1 text-xs rounded-full ml-2 px-2 font-medium border border-gray-200 bg-gray-100 text-gray-700">
                Coming Soon
              </div>
            </ListItemButton>
          </Link>
        </List>
        <List
          sx={{
            position: 'fixed',
            bottom: '10px',
            width: '100%',
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: '#9553FE',
              '&, & .MuiListItemIcon-root': {
                color: '#FFFFFF'
              }
            }
          }}
        >
          <Link>
            <ListItemButton onClick={signOut}>
              <ListItemIcon sx={{ minWidth: '44px' }}>
                <LogoutIcon />
              </ListItemIcon>
              <span className="text-sm">Logout</span>
              {/* <ListItemText primary="Logout" /> */}
            </ListItemButton>
          </Link>
        </List>
      </div>
    </div>
  );
}

export default Sidebar;

import { Card, CardContent, Grid } from '@mui/material';
import EventTypeKeyCard from '../EventType/EventKeyCard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MonitorCard = ({ monitor }) => {
  return (
    <Card>
      <CardContent>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <EventTypeKeyCard heading={'Primary'} event_type_key={monitor?.primary_event_key} />
          </Grid>
          <Grid item>
            <ArrowForwardIcon />
          </Grid>
          <Grid item>
            <EventTypeKeyCard heading={'Secondary'} event_type_key={monitor?.secondary_event_key} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MonitorCard;

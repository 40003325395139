import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab';
import EventCard from './EventCard';
import { CardContent, Divider } from '@mui/material';
import EventsTimeline from './EventsTimeline';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { React } from 'react';

const EventsTimelineCard = ({ events }) => {
  return (
    <Card>
      <CardHeader title={'Event Timeline'} />
      <Divider />
      <CardContent>
        <EventsTimeline events={events} />
      </CardContent>
    </Card>
  );
};

export default EventsTimelineCard;

import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import EventTypeLink from './EventTypeLink';

const EventTypeKeyCard = ({ heading, event_type_key }) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="body2" sx={{ color: 'grey' }}>
              {heading} Event Type
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              <EventTypeLink event_type={event_type_key?.event_type} />
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" sx={{ color: 'grey' }}>
              Key
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 100 }}>
              {event_type_key?.key}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EventTypeKeyCard;

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab';
import EventCard from './EventCard';

const EventsTimeline = ({ events }) => {
  return (
    <Timeline>
      {events?.map(elem => {
        return (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <EventCard eventData={elem} />
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default EventsTimeline;

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MonitorsTable from './MonitorsTable';
import { Link } from 'react-router-dom';
import CreateMonitorLink from './CreateMonitorLink';
import React from 'react';

const MonitorsTableCard = ({
  extraHeader,
  monitors,
  total,
  pageSize,
  pageUpdateCb,
  tableContainerStyles
}) => {
  return (
    <div className="border border-gray-300 rounded-md bg-white">
      <div className="flex justify-between bg-white py-2 rounded-t-md px-4 border-b items-center">
        <Link to={`/monitors`} style={{ textDecoration: 'none' }}>
          <div className="text-base text-gray-800 hover:text-violet-700">
            Monitors{' '}
            <span className="text-sm text-gray-500 ml-2">
              {extraHeader ? ` (${extraHeader})` : null}
            </span>
          </div>
        </Link>

        <div>
          <Link to="/monitors/create">
            <div className="text-sm rounded-lg py-2 px-2 cursor-pointer border-violet-600 text-violet-600 dura hover:text-violet-700 hover:bg-violet-50 flex">
              <span className="w-5 mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              </span>
              <span>Create Monitor</span>
            </div>
          </Link>
          {/* <CreateMonitorLink /> */}
        </div>
      </div>
      {/* <CardHeader
        title={
          <Link to={`/monitors`} style={{ textDecoration: 'none' }}>
            <div className='text-lg'>
              Monitors {extraHeader ? `(${extraHeader})` : null}
            </div>
          </Link>
        }
        action={
          <div style={{ marginRight: '1vw', marginTop: '0.5vh' }}>
            <CreateMonitorLink />
          </div>
        }
      /> */}
      <MonitorsTable
        monitors={monitors}
        total={total}
        pageSize={pageSize}
        pageUpdateCb={pageUpdateCb}
        tableContainerStyles={tableContainerStyles}
      />
    </div>
  );
};

export default MonitorsTableCard;

export const groupedData = response => {
  const responseEventsData = response?.data?.monitor_options?.event_key_options;
  const responseNotificationsData = response?.data?.monitor_options?.notification_options;
  const responseTriggersData = response?.data.monitor_options?.trigger_options;

  return {
    eventTypeMap: groupEventTypesById(responseEventsData),
    notificationMap: getNotificationHashMap(responseNotificationsData),
    triggerMap: responseTriggersData
  };
};

const groupEventTypesById = responseEventsData => {
  const eventTypeMap = {};

  if (!responseEventsData) {
    return eventTypeMap;
  }

  responseEventsData.forEach(option => {
    const { id, key, event_type } = option;
    const { name: eventTypeName } = event_type;

    if (!eventTypeMap[eventTypeName]) {
      eventTypeMap[eventTypeName] = [];
    }

    eventTypeMap[eventTypeName].push({
      key,
      id
    });
  });

  return eventTypeMap;
};

const getNotificationHashMap = responseNotificationsData => {
  const notificationHashMap = {};

  if (!responseNotificationsData) {
    return notificationHashMap;
  }

  responseNotificationsData.forEach(option => {
    const { channel } = option;
    const values = {};

    if (option.slack_configuration) {
      values.webhook_url = option.slack_configuration.webhook_url;
    }

    if (option.email_configuration) {
      values.email_address = option.email_configuration.recipient_email_id;
    }

    notificationHashMap[channel] = values;
  });

  return notificationHashMap;
};

import { useCallback, useEffect, useState } from 'react';
import API from '../API';
import Heading from '../components/Heading';
import { CircularProgress, Container } from '@mui/material';
import PaginatedTable from '../components/PaginatedTable';
import EventsTable from '../components/Events/EventsTable';
import SuspenseLoader from '../components/Skeleton/SuspenseLoader';
import TableSkeleton from '../components/Skeleton/TableLoader';

const Events = () => {
  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState([]);

  const [total, setTotal] = useState(0);
  const [pageMeta, setPageMeta] = useState({ limit: 10, offset: 0 });

  const fetchEvents = API.useGetEvents();

  useEffect(() => {
    if (loading) {
      fetchEvents(
        {
          meta: {
            page: pageMeta
          }
        },
        res => {
          setLoading(false);
          setEvents(res.data?.events);
          setTotal(Number(res.data?.meta?.total_count));
        },
        err => {
          setLoading(false);
        }
      );
    }
  }, [loading]);

  const loadingCb = useCallback(() => {
    setLoading(true);
  }, [loading]);

  const pageUpdateCb = useCallback(
    (page, successCb, errCb) => {
      setPageMeta(page);
      fetchEvents(
        {
          meta: {
            page: page
          }
        },
        res => {
          setEvents(res.data?.events);
          setTotal(Number(res.data?.meta?.total_count));
          successCb(res.data?.events, Number(res.data?.meta?.total_count));
        },
        err => {
          errCb(err);
        }
      );
    },
    [fetchEvents]
  );

  return (
    <>
      <Heading heading={'Events'} onTimeRangeChangeCb={loadingCb} onRefreshCb={loadingCb} />
      <SuspenseLoader loading={!!loading} loader={<TableSkeleton />}>
        <PaginatedTable
          loading={loading}
          renderTable={EventsTable}
          data={events}
          total={total}
          pageSize={pageMeta ? pageMeta?.limit : 10}
          pageUpdateCb={pageUpdateCb}
        />
      </SuspenseLoader>
    </>
  );
};

export default Events;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../API';
import Heading from '../components/Heading';
import SuspenseLoader from '../components/Skeleton/SuspenseLoader';
import TableSkeleton from '../components/Skeleton/TableLoader';
import { EVENT_TYPES, EVENT_TYPE_DISPLAY, TRANSACTIONS_TYPE } from '../constants';
import logo from '../data/black_logo_beta.png';
import { DataGrid } from '@mui/x-data-grid';

const Alert = () => {
  let { alertId } = useParams();
  const [alertData, setAlertData] = useState({});
  const [eventPairData, setEventPairData] = useState([]);
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);
  const fetchAlertDetail = API.useGetAlertDetailData();
  const fetchAlertMonitorTransactions = API.useGetAlertMonitorTransactions();

  const renderKeyValuePair = (key, value) => {
    return (
      <div className="flex sm:flex-1 flex-row p-0 mr-8">
        <span className="text-gray-500 text-xs leading-4 mr-2">{key} </span>
        <span className="text-gray-800 text-xs leading-4">{value}</span>
      </div>
    );
  };

  const columns = [
    {
      field: 'created_at',
      headerName: 'Event Occured at',
      flex: 1,
      valueGetter: params => {
        return moment.unix(params?.row?.created_at).fromNow();
      }
    },
    {
      field: 'primary_event_name',
      headerName: 'Events',
      flex: 1,
      valueGetter: params => {
        const transactionType = params.row.type;
        if (transactionType === TRANSACTIONS_TYPE.DELAYED_TRANSACTION) {
          return `${params.row.primary_event_name} -> ${params.row.secondary_event_name}`;
        }
        return `${params.row.primary_event_name}`;
      }
    },
    { field: 'transaction', headerName: 'Primary Key Value', flex: 1 }
  ];

  useEffect(() => {
    setLoading(true);
    fetchAlertDetail(
      alertId,
      respAlertData => {
        setAlertData(respAlertData.data?.alert_detail);
        const {
          trigger: {
            definition,
            monitor: { primary_event_key, secondary_event_key }
          }
        } = respAlertData.data?.alert_detail;
        fetchAlertMonitorTransactions(
          alertId,
          response => {
            const monitortransactionsData = response?.data?.monitor_transactions;
            const eventPairs = monitortransactionsData.map(monitorTransaction => ({
              id: monitorTransaction?.monitor_transaction?.id,
              type: monitorTransaction?.type,
              created_at: monitorTransaction?.monitor_transaction?.created_at,
              transaction: monitorTransaction?.monitor_transaction?.transaction,
              primary_event_name: primary_event_key?.event_type?.name,
              secondary_event_name: secondary_event_key?.event_type?.name
            }));
            setEventPairData(eventPairs);
            setAlertType(definition?.type);
            setLoading(false);
          },
          error => {
            setLoading(false);
            console.error('Error while fetching monitor transaction');
          }
        );
      },
      error => {
        setLoading(false);
        console.error('Error while fetching alert details data');
      }
    );
  }, []);
  return (
    <>
      <div className="py-2 px-3 border-b border-gray-300 bg-white flex sm:hidden">
        <img src={logo} alt="Logo" style={{ width: '129px' }} />
      </div>
      <Heading heading={`Alert / #${alertId}`}></Heading>
      <SuspenseLoader loading={!!loading} loader={<TableSkeleton />}>
        <div className="flex flex-col items-start p-0 m-3">
          <div className="border border-gray-200 rounded bg-white flex flex-row flex-wrap items-start py-2 px-3 w-full mb-2 sm:h-full h-24">
            {renderKeyValuePair('Type', EVENT_TYPE_DISPLAY[alertType])}
            {renderKeyValuePair('ID', `#${alertId}`)}
            {renderKeyValuePair('Created', moment(alertData?.triggered_at).fromNow())}
            {renderKeyValuePair('Monitor', alertData?.trigger?.monitor?.name)}
          </div>
          {alertType === EVENT_TYPES.MISSING_EVENT && (
            <div className="border border-gray-200 rounded bg-white flex flex-row flex-wrap items-start py-2 px-3 w-full mb-2 sm:h-full h-24">
              {renderKeyValuePair(
                'Event Occured at',
                moment.unix(eventPairData?.[0]?.created_at).fromNow()
              )}
              {renderKeyValuePair('Events', eventPairData?.[0].primary_event_name)}
              {renderKeyValuePair('Primary Key Value', eventPairData?.[0].transaction)}
            </div>
          )}
          {alertType === EVENT_TYPES.DELAYED_EVENT && (
            <>
              <div className="border border-gray-200 rounded bg-white flex flex-row flex-wrap items-start py-2 px-3 w-full mb-2 sm:h-full h-24">
                {renderKeyValuePair(
                  'Evaluation Interval',
                  `${alertData?.trigger?.definition?.delayed_event_trigger?.resolution} secs`
                )}
                {renderKeyValuePair(
                  'Primary events occured',
                  alertData?.stats?.total_transaction_count
                )}
                {renderKeyValuePair(
                  'Events Pair Missed',
                  alertData?.stats?.missed_transaction_count
                )}
                {renderKeyValuePair('Median Delay Time', alertData?.stats?.median_delay)}
              </div>
              <div className="border border-gray-200 bg-white flex flex-row items-start py-2 px-3 w-full">
                <span className="text-gray-600 text-xs leading-4">All Events Pair</span>
              </div>
              <div className="border border-gray-200 bg-white flex flex-row items-start w-full h-fit sm:flex hidden">
                <DataGrid
                  sx={{
                    '.MuiDataGrid-columnSeparator': {
                      display: 'none'
                    },
                    '&.MuiDataGrid-root': {
                      border: 'none'
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      lineHeight: '150%',
                      color: '#6B7280'
                    },
                    '.MuiDataGrid-cellContent': {
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      lineHeight: '150%',
                      color: '#000'
                    },
                    '.MuiDataGrid-row:hover': {
                      backgroundColor: 'white'
                    }
                  }}
                  hideFooter
                  autoHeight
                  disableColumnMenu
                  sortable={false}
                  hideFooterPagination
                  rows={eventPairData}
                  columns={columns.map(column => ({
                    ...column,
                    sortable: false
                  }))}
                  headerHeight={34}
                  rowHeight={32}
                  getRowId={params => params?.id}
                  disableSelectionOnClick
                />
              </div>
              <div className="border border-gray-200 rounded bg-white flex flex-row flex-wrap items-start py-2 px-3 w-full mb-2 sm:hidden flex">
                <div className="flex sm:flex-1 flex-row p-1">
                  <span className="text-gray-500 text-xs">
                    List of Events will get displayed in desktop version.
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </SuspenseLoader>
      {/* <Toast
        open={toastOpen}
        handleClose={handleCloseToast}
        message={toastMsg}
        severity={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      /> */}
    </>
  );
};

export default Alert;

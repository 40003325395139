import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Link } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';

import moment from 'moment';
import PaginatedTable from '../PaginatedTable';
import MonitorLink from '../Monitors/MonitorLink';
import NoExistingEventType from '../EventType/NoExistingEventType';
import NoExistingAlert from './NoExistingAlert';

const AlertsTableRender = ({ data, loading }) => {
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Monitor</TableCell>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Events</TableCell>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Timestamp</TableCell>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell align="left">
                <MonitorLink
                  monitor={item?.trigger?.monitor_details?.monitor}
                  style={{ color: 'grey' }}
                />
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={item?.trigger?.monitor_details?.primary_event_type.name}
                  // color="success"
                  style={{ backgroundColor: 'lightgreen' }}
                  sx={{ marginRight: 1 }}
                />
                <span> &rarr;</span>
                <Chip
                  label={item?.trigger?.monitor_details?.secondary_event_type.name}
                  // color="secondary"
                  style={{ backgroundColor: 'lightblue' }}
                  sx={{ marginLeft: 1 }}
                />
              </TableCell>
              <TableCell align="left">{moment.unix(item?.triggered_at).fromNow()}</TableCell>
              <TableCell align="left">
                <Link to={`/alerts/${item?.id}`}>
                  <VisibilityIcon />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!data?.length ? <NoExistingAlert /> : null}
    </>
  );
};

const AlertsTable = ({ alerts, total, pageSize, pageUpdateCb, tableContainerStyles }) => {
  return (
    <PaginatedTable
      renderTable={AlertsTableRender}
      data={alerts}
      total={total}
      pageSize={pageSize}
      pageUpdateCb={pageUpdateCb}
      tableContainerStyles={tableContainerStyles ? tableContainerStyles : {}}
    />
  );
};

export default AlertsTable;

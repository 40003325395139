import { createContext, useCallback, useState } from 'react';

const getCurrentTimeStamp = () => {
  return Math.floor(Date.now() / 1000);
};

const defaultTimeRangeOptions = {
  '5m': {
    displayLabel: '5 Minutes',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 300, time_lt: currentTimestamp };
    }
  },
  '15m': {
    displayLabel: '15 Minutes',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 900, time_lt: currentTimestamp };
    }
  },
  '30m': {
    displayLabel: '30 Minutes',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 1800, time_lt: currentTimestamp };
    }
  },
  '1h': {
    displayLabel: '1 Hour',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 3600, time_lt: currentTimestamp };
    }
  },
  '3h': {
    displayLabel: '3 Hours',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 10800, time_lt: currentTimestamp };
    }
  },
  '6h': {
    displayLabel: '6 Hours',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 21600, time_lt: currentTimestamp };
    }
  },
  '1d': {
    displayLabel: '1 Day',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 86400, time_lt: currentTimestamp };
    }
  },
  '3d': {
    displayLabel: '3 Days',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 259200, time_lt: currentTimestamp };
    }
  },
  '7d': {
    displayLabel: '7 Days',
    getTimeRange: () => {
      let currentTimestamp = getCurrentTimeStamp();
      return { time_geq: currentTimestamp - 604800, time_lt: currentTimestamp };
    }
  }
};

const TimeRangeContext = createContext({});

export const TimeRangeProvider = ({ children }) => {
  const [timeRange, setTimeRange] = useState('5m');

  const updateTimeRange = value => {
    setTimeRange(value);
  };

  const getTimeRange = useCallback(() => {
    return defaultTimeRangeOptions[timeRange].getTimeRange();
  }, [timeRange]);

  const getTimeRangeOptions = () => {
    return defaultTimeRangeOptions;
  };

  return (
    <TimeRangeContext.Provider
      value={{ timeRange, updateTimeRange, getTimeRangeOptions, getTimeRange }}
    >
      {children}
    </TimeRangeContext.Provider>
  );
};

export default TimeRangeContext;

import { useNavigate, useParams } from 'react-router-dom';
import Heading from '../components/Heading';
import API from '../API';
import { useCallback, useEffect, useState } from 'react';
import MonitorCard from '../components/Monitors/MonitorCard';
import { CircularProgress, Grid, Tab } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import TabList from '../components/TabList';
import MonitorTransactionsByStatusTab from '../components/Monitors/tabs/ActiveMonitorTransactionTab';

const tabs = ['active', 'finished'];

const getTab = activeTab => {
  if (!activeTab) {
    return tabs[0];
  }
  if (!tabs.includes(activeTab)) {
    return tabs[0];
  }
  return activeTab;
};

const Monitor = () => {
  let { id, tab } = useParams();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [monitorName, setMonitorName] = useState('');
  const [monitor, setMonitor] = useState({});
  const fetchMonitors = API.useGetMonitors();

  const [tabValue, setTabValue] = useState(getTab(tab));
  const [tabLoading, setTabLoading] = useState(true);
  const [tabChange, setTabChange] = useState(false);

  useEffect(() => {
    if (loading) {
      fetchMonitors([id], {}, response => {
        if (response.data?.monitors?.length > 0) {
          setMonitor(response.data?.monitors[0]);
          setMonitorName(response.data?.monitors[0]?.name);
        }
        setLoading(false);
      });
    }
  }, [loading]);

  const loadingCb = useCallback(() => {
    setLoading(true);
    setTabLoading(true);
  }, [loading]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setTabChange(true);
    navigate(`/monitors/${id}/${newValue.toLowerCase()}`);
  };

  return (
    <>
      <Heading
        subHeading="Monitor"
        heading={loading ? <CircularProgress /> : `${monitorName}`}
        onTimeRangeChangeCb={loadingCb}
        onRefreshCb={loadingCb}
      />
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <MonitorCard monitor={monitor} />
        </Grid>
        <Grid item>
          <TabContext value={tabValue}>
            <TabList onChange={handleTabChange}>
              <Tab value="active" label="Active" />
              <Tab value="finished" label="Finished" />
            </TabList>
            <TabPanel value="active">
              <MonitorTransactionsByStatusTab
                monitor_id={id}
                loading={tabLoading}
                change={tabChange}
                transaction_status={'PRIMARY_RECEIVED'}
                onTabLoadCb={() => setTabLoading(false)}
                onTabChangeCb={() => setTabChange(false)}
              />
            </TabPanel>
            <TabPanel value="finished">
              <MonitorTransactionsByStatusTab
                monitor_id={id}
                loading={tabLoading}
                change={tabChange}
                transaction_status={'SECONDARY_RECEIVED'}
                onTabLoadCb={() => setTabLoading(false)}
                onTabChangeCb={() => setTabChange(false)}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
};

export default Monitor;

import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import API from '../API';
import Heading from '../components/Heading';
import EventTypesTableCard from '../components/EventType/EventTypesTableCard';
import AlertsTableCard from '../components/Alerts/AlertsTableCard';
import SuspenseLoader from '../components/Skeleton/SuspenseLoader';
import TableSkeleton from '../components/Skeleton/TableLoader';
import MonitorsTableCard from '../components/Monitors/MonitorsTableCard';

const Home = () => {
  const [loading, setLoading] = useState(true);

  const page = { limit: 5, offset: 0 };

  const [eventTypeSummaries, setEventTypeSummaries] = useState([]);
  const [totalEventTypeSummaries, setTotalEventTypeSummaries] = useState(0);
  const [isEventTypeSummariesFetched, setIsEventTypeSummariesFetched] = useState(false);

  const [alerts, setAlerts] = useState([]);
  const [isAlertSummariesFetched, setIsAlertSummariesFetched] = useState(false);
  const [totalAlerts, setTotalAlerts] = useState(0);

  const [monitors, setMonitors] = useState([]);
  const [isMonitorsFetched, setIsMonitorsFetched] = useState(false);
  const [totalMonitors, setTotalMonitors] = useState(0);

  const fetchEventTypeSummary = API.useGetEventTypeSummary();
  const fetchAlerts = API.useGetAlerts();
  const fetchMonitors = API.useGetMonitors();

  useEffect(() => {
    if (!loading) {
      return;
    }
    fetchEventTypeSummary([], page, response => {
      setEventTypeSummaries(response?.data?.event_type_summary);
      setTotalEventTypeSummaries(Number(response.data?.meta?.total_count));
      setIsEventTypeSummariesFetched(true);
    });
    fetchAlerts(page, response => {
      setAlerts(response.data?.alerts);
      setTotalAlerts(Number(response.data?.meta?.total_count));
      setIsAlertSummariesFetched(true);
    });
    fetchMonitors([], page, response => {
      if (response.data?.monitors) {
        setMonitors(response.data?.monitors);
      }
      setTotalMonitors(Number(response.data?.meta?.total_count));
      setIsMonitorsFetched(true);
    });
    setLoading(false);
  }, [loading]);

  const loadingCb = useCallback(() => {
    setLoading(true);
    setIsEventTypeSummariesFetched(false);
    setIsAlertSummariesFetched(false);
    setIsMonitorsFetched(false);
  }, [loading]);

  return (
    <>
      <Heading heading={'Home'} onTimeRangeChangeCb={loadingCb} onRefreshCb={loadingCb} />
      <div className="py-4 px-4">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <SuspenseLoader loading={!isMonitorsFetched} loader={<TableSkeleton noOfLines={2} />}>
              <MonitorsTableCard
                extraHeader={totalMonitors}
                monitors={monitors}
                total={monitors?.length}
                pageSize={null}
                tableContainerStyles={monitors?.length ? {} : {}}
              />
            </SuspenseLoader>
          </Grid>
          <Grid item>
            <SuspenseLoader
              loading={!isAlertSummariesFetched}
              loader={<TableSkeleton noOfLines={2} />}
            >
              <AlertsTableCard
                extraHeader={totalAlerts}
                alerts={alerts}
                total={alerts?.length}
                pageSize={null}
                tableContainerStyles={alerts?.length ? {} : {}}
              />
            </SuspenseLoader>
          </Grid>
          <Grid item>
            <SuspenseLoader
              loading={!isEventTypeSummariesFetched}
              loader={<TableSkeleton noOfLines={2} />}
            >
              <EventTypesTableCard
                extraHeader={totalEventTypeSummaries}
                eventTypeSummaries={eventTypeSummaries}
                total={eventTypeSummaries?.length}
                pageSize={null}
                tableContainerStyles={
                  alerts?.length ? {} : { maxHeight: '35vh', minHeight: '35vh' }
                }
              />
            </SuspenseLoader>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Home;

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import '../src/Layout.css';
import { Divider } from 'antd';

const RightPanel = () => {
  return (
    <div className="rightPanel py-4">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '30px'
        }}
      >
        {/* <Typography sx={{ margin: '30px 0px', fontWeight: 'bold' }} variant="h5">
          Welcome to Dr. Droid's Beta
        </Typography> */}
        <iframe
          width="100%"
          height="100%"
          src="https://www.loom.com/embed/79f5fa10f57f4227a78d2c98969400ff"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded"
        />
      </div>
      <div>
        <a className="font-medium text-purple-700 text-base text-voilet-600 hover:underline cursor-pointer">
          Learn how to setup Monitors
        </a>
        <div className="text-sm text-gray-500 mb-2">Simple steps you get you started</div>
        <ul style={{ color: 'grey', fontSize: '14px', marginBottom: '60px' }}>
          <li>- Check out our demo video</li>
          <li>
            -{' '}
            <a
              href="https://docs.drdroid.io/reference/events"
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              Send a sample event to our platform
            </a>
          </li>
          <li>
            -{' '}
            <Link style={{ textDecoration: 'underline' }} to="/monitors/create">
              Create your first monitor
            </Link>
          </li>
          <li>- Integrate slack hyperlink to open slack integration</li>
          <li>- Setup email / SMS triggers</li>
          <li>- Have questions or feedback? Message us and share your inputs</li>
        </ul>
        <div>
          <Link
            style={{ color: '#9553FE', textDecoration: 'underline' }}
            to="https://docs.drdroid.io"
            target="_blank"
          >
            Documentation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;

import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import MonitorLink from './MonitorLink';
import React from 'react';
import PaginatedTable from '../PaginatedTable';
import NoExistingMonitor from './NoExistingMonitor';

const MonitorsTableRender = ({ data, loading }) => {
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: 'black', fontWeight: 'bold' }}>Name</TableCell>
            <TableCell align="left" style={{ color: 'black', fontWeight: 'bold' }}>
              Events
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell component="th" scope="row">
                <MonitorLink monitor={item} style={{ color: 'grey' }} />
              </TableCell>
              <TableCell align="left">
                <Chip
                  label={item.primary_event_key.event_type.name}
                  // color="success"
                  style={{ backgroundColor: 'lightgreen' }}
                  sx={{ marginRight: 1 }}
                />
                <span> &rarr;</span>
                <Chip
                  label={item.secondary_event_key.event_type.name}
                  // color="secondary"
                  style={{ backgroundColor: 'lightblue' }}
                  sx={{ marginLeft: 1 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!data?.length ? <NoExistingMonitor /> : null}
    </>
  );
};

const MonitorsTable = ({ monitors, total, pageSize, pageUpdateCb, tableContainerStyles }) => {
  return (
    <PaginatedTable
      renderTable={MonitorsTableRender}
      data={monitors}
      total={total}
      pageSize={pageSize}
      tableContainerStyles={tableContainerStyles ? tableContainerStyles : {}}
      pageUpdateCb={pageUpdateCb}
    />
  );
};

export default MonitorsTable;

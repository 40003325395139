import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import EventTypeLink from '../EventType/EventTypeLink';
import functions from '../../utils/Utility';
import React, { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:nth-of-type(4n+2)': {
    backgroundColor: theme.palette.action.hover
  }
}));

const EventRow = ({ event }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {event?.id}
        </TableCell>
        <TableCell align="left">{dayjs.unix(Number(event?.timestamp)).toString()}</TableCell>
        <TableCell align="left">
          <EventTypeLink event_type={event?.event_type} style={{ textDecoration: 'underline' }} />
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Attributes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Key</b>
                    </TableCell>
                    <TableCell>
                      <b>Value</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{functions.renderEventKvs(event)}</TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

const EventsTable = ({ loading, data }) => {
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Table aria-label="events table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell align="left">
              <b>Event Id&nbsp;</b>
            </StyledTableCell>
            <StyledTableCell align="left">
              <b>Timestamp&nbsp;</b>
            </StyledTableCell>
            <StyledTableCell align="left">
              <b>Event Type&nbsp;</b>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(row => (
            <EventRow event={row} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default EventsTable;

import React from 'react';
import { Link } from 'react-router-dom';

const MonitorLink = ({ monitor, tab, style }) => {
  return (
    <Link
      to={`/monitors/${monitor?.id}/${tab ? tab : ''}`}
      style={{ textDecoration: 'none', ...style }}
    >
      {monitor?.name}
    </Link>
  );
};

export default MonitorLink;

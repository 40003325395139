import useAxiosPrivate from './hooks/useAxiosPrivate';
import useTimeRange from './hooks/useTimeRange';
import { useCallback } from 'react';

const defaultRequestHeaders = { 'Content-Type': 'application/json' };

const useApiCallback = () => {
  const { axiosPrivate } = useAxiosPrivate();
  const { timerange, getTimeRange } = useTimeRange();

  const updateTimeRangeRequestData = useCallback(
    (setTimeRange, requestData) => {
      if (!setTimeRange || requestData === undefined) {
        return requestData;
      }
      if ('meta' in requestData) {
        requestData.meta['time_range'] = getTimeRange();
      } else {
        requestData['meta'] = {
          time_range: getTimeRange()
        };
      }
      return requestData;
    },
    [getTimeRange]
  );

  const callback = useCallback(
    (
      endpoint,
      requestData,
      onSuccess,
      onError = e => {
        console.log(e);
      },
      setTimeRange = true
    ) => {
      axiosPrivate
        .post(endpoint, updateTimeRangeRequestData(setTimeRange, requestData), {
          headers: { ...defaultRequestHeaders },
          withCredentials: true
        })
        .then(onSuccess)
        .catch(onError);
    },
    [timerange, getTimeRange, axiosPrivate]
  );

  return callback;
};

const apis = {
  useGetEvents: () => {
    const apiCallback = useApiCallback();
    const callback = (
      requestData,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      apiCallback('/e/api/events', requestData, onSuccess, onError, true);
    };

    return callback;
  },
  useGetEventTypeSummary: () => {
    const apiCallback = useApiCallback();
    const callback = (
      event_type_ids,
      page,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        event_type_ids: event_type_ids,
        meta: {
          page: page
        }
      };
      apiCallback('/e/api/event_types/summary', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetAlerts: () => {
    const apiCallback = useApiCallback();
    const callback = (
      page,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        meta: {
          page: page
        }
      };
      apiCallback('/e/api/alerts/summary', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetAlertDetailData: () => {
    const apiCallback = useApiCallback();
    const callback = (
      alert_id,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        meta: {
          // time_range: TIME_RANGE,
        },
        alert_id: parseInt(alert_id)
      };
      apiCallback('/e/api/alerts/details', requestData, onSuccess, onError, false);
    };

    return callback;
  },

  useGetAlertMonitorTransactions: () => {
    const apiCallback = useApiCallback();
    const callback = (
      alert_id,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        meta: {
          //   page: page
        },
        alert_id: parseInt(alert_id)
      };
      apiCallback('/e/api/alerts/monitor_transactions', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetAlertDetails: () => {
    const apiCallback = useApiCallback();
    const callback = (
      alert_id,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        meta: {
          // time_range: TIME_RANGE,
        },
        alert_id: parseInt(alert_id)
      };
      apiCallback('/e/api/alerts/details', requestData, onSuccess, onError, false);
    };

    return callback;
  },
  useGetEventTypeDefinition: () => {
    const apiCallback = useApiCallback();

    const callback = (
      // meta,
      event_type_ids,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        event_type_ids: event_type_ids
      };
      apiCallback('/e/api/event_types/definition', requestData, onSuccess, onError);
    };

    return callback;
  },
  useCreateMonitors: () => {
    const apiCallback = useApiCallback();
    const callback = (
      requestData,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      apiCallback('/e/api/monitors/create', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetMonitors: () => {
    const apiCallback = useApiCallback();
    const callback = (
      monitor_ids,
      page,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        monitor_ids: monitor_ids,
        meta: {
          page: page
        }
      };
      apiCallback('/e/api/monitors', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetMonitorOptions: () => {
    const apiCallback = useApiCallback();
    const callback = (
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      apiCallback('/e/api/monitors/options', {}, onSuccess, onError);
    };

    return callback;
  },
  useGetMonitorsDefinition: () => {
    const apiCallback = useApiCallback();
    const callback = (
      monitor_ids,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        monitor_ids: monitor_ids
      };
      apiCallback('/e/api/monitors/definition', requestData, onSuccess, onError);
    };

    return callback;
  },
  useGetMonitorTransactions: () => {
    const apiCallback = useApiCallback();
    const callback = (
      monitor_id,
      params,
      page,
      onSuccess,
      onError = e => {
        console.log(e);
      },
      setTimeRange = true
    ) => {
      const requestData = {
        monitor_id: monitor_id,
        params: params,
        meta: {
          page: page
        }
      };
      apiCallback('/e/api/monitors/transactions', requestData, onSuccess, onError, setTimeRange);
    };

    return callback;
  },
  useGetMonitorsTransactionsEvents: () => {
    const apiCallback = useApiCallback();
    const callback = (
      transaction_id,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      const requestData = {
        transaction_id: transaction_id
      };
      apiCallback('/e/api/monitors/transactions/events', requestData, onSuccess, onError, false);
    };

    return callback;
  },

  useGetAccountApiToken: () => {
    const apiCallback = useApiCallback();
    const callback = (
      requestData,
      onSuccess,
      onError = e => {
        console.log(e);
      }
    ) => {
      apiCallback('/accounts/account_api_tokens', requestData, onSuccess, onError, false);
    };

    return callback;
  }
};

export default apis;

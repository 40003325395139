import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useTimeRange from '../hooks/useTimeRange';

const renderTimeRangeMenuItems = timeRangeOptions => {
  if (timeRangeOptions === undefined) {
    return;
  }

  return Object.keys(timeRangeOptions).map(key => (
    <MenuItem key={key} value={key}>
      {timeRangeOptions[key].displayLabel}
    </MenuItem>
  ));
};

const TimeRangePicker = ({ onTimeRangeChangeCb }) => {
  const { timeRange, updateTimeRange, getTimeRangeOptions } = useTimeRange();

  const onTimeRangeChange = event => {
    updateTimeRange(event.target.value);
    if (onTimeRangeChangeCb) {
      onTimeRangeChangeCb();
    }
  };

  return (
    <FormControl style={{ minWidth: 140 }} size="small">
      <InputLabel id="time-range-select-label">Time Range</InputLabel>
      <Select
        value={timeRange}
        label="TimeRange"
        id="time-range-select"
        onChange={onTimeRangeChange}
        labelId="time-range-select-label"
      >
        {renderTimeRangeMenuItems(getTimeRangeOptions())}
      </Select>
    </FormControl>
  );
};

export default TimeRangePicker;
